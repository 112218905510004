import React, { useEffect, useState } from 'react'
import Header from '../Components/header'
import { Button, TextField } from '@mui/material'
import Swal from 'sweetalert2'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { store } from '../store'
import { setUser } from '../store/reducers/slices'
import ReactGA from 'react-ga4'

export default function Recharge() {
  ReactGA.send({ hitType: 'pageview', page: '/recharge', title: 'Recharge' })

  const navigate = useNavigate()
  const { user } = useSelector(state => state.user)

  const [ amount, setAmount ] = useState(null)
  const [ winNumber, setWinNumber ] = useState(null)
  const [ mustSpin, setMustSpin ] = useState(false)
  const [ discountedAmount, setDiscountedAmount ] = useState(null)

  useEffect(() => {
    if (winNumber) {
      applyDiscount()
    }
  }, [ winNumber ])

  const data = [
    { key: 'Minimum recharge amount is 800' },
    { key: 'Confirm the recharge amount and fill in the UTR number correctly.' },
    { key: 'Every time you recharge, you need to re-acquire the receiving account at the cashier.' },
    { key: 'For recharge questions, please contact online customer service.' },
    { key: 'Upload Screenshot after payment done once we verify your amount will credit in your wallet ' }
  ]

  async function handleAmountValidation() {
    if (user?.spinCount) {
      if (!amount) {
        Swal.fire({
          text: "Please enter amount!",
          icon: "info"
        });
        return
      }

      if (amount < 800) {
        Swal.fire({
          text: "Invalid amount!",
          icon: "info"
        });
        return
      }
    }

    await store.dispatch(setUser({ discountedAmount: Number(discountedAmount), actualAmount: Number(amount) }))
    navigate('/payment-options')
  }

  const spinData = [
    { option: '1' },
    { option: '2' },
    { option: '3' },
    { option: '4' },
    { option: '5' },
    { option: '6' },
    { option: '7' },
    { option: '8' },
    { option: '9' },
    { option: '10' }
  ]
  const handleDiscount = async () => {
    if (!mustSpin) {
      const winVal = Math.floor(Math.random() * data.length)
      setMustSpin(true)
      await setWinNumber(spinData[winVal]?.option)
      if (user.spinCount === 0) {
        setMustSpin(false)
        return
      }
      await store.dispatch(setUser({ spinCount: 0 }))
    }
  }

  async function applyDiscount() {
    Swal.fire({
      text: `You got ${winNumber}% discount in your recharge`,
      icon: "success"
    });
    return setDiscountedAmount(Math.round(amount - (amount * (winNumber / 100))))
  }

  return (
      <div className="h-screen bg-gradient-to-b from-teal-500 to-white">
        <Header navigation={'/'} updateRedux={true}/>
        <div className="overflow-y-scroll flex flex-col items-center mt-10">
          <div className="w-5/6 sm:w-4/6 lg:w-2/6">
            <div className="mb-12 flex flex-col itemx-center justify-center">
              <div>
                <p className="text-lg font-bold">Enter amount :</p>
                <TextField
                    disabled={user.spinCount ? false : true}
                    className="w-full"
                    id="outlined-basic"
                    label={<span style={{ color: 'black' }}>Amount</span>}
                    variant="outlined"
                    type="number"
                    value={!discountedAmount ? amount : discountedAmount}
                    onChange={(e) => setAmount(e.target.value)}
                    sx={{
                      marginTop: 2,
                      color: 'white',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#000'
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#000'
                        }
                      }
                    }}
                />
              </div>
              {
                amount >= 800 || !user.spinCount ?
                    <button
                        disabled={user.spinCount ? false : true}
                        className={`mx-auto px-10 py-2 my-4 text-lg text-white font-medium rounded-full ${user.spinCount > 0 ? 'bg-teal-500' : 'bg-teal-300'}`}
                        onClick={handleDiscount}>
                      Apply discount for recharge
                    </button> : null
              }
              <div className="flex flex-col gap-3 mt-10">
                {
                  data.map((item, index) => {
                    return (
                        <div key={index} className="text-lg font-medium">
                          • {item.key}
                        </div>
                    )
                  })
                }
              </div>
              <Button
                  className="w-full"
                  sx={{
                    bgcolor: 'rgb(45 212 191)',
                    marginTop: 4,
                    border: '1px solid rgb(94 234 212)',
                    padding: 1,
                    color: '#000',
                    borderRadius: 2,
                    fontSize: 14,
                    fontWeight: 600,
                    boxShadow: 'none',
                    textTransform: 'capitalize',
                    '&:hover': {
                      bgcolor: 'rgb(20 184 166)',
                      color: '#000'
                    }
                  }}
                  onClick={handleAmountValidation}
              >Confirm Recharge</Button>
            </div>
          </div>
        </div>
      </div>
  )
}
