import React, { useEffect, useState } from 'react'
import { logoImg, sliderImg1, sliderImg2, sliderImg3, specialOfferImg } from '../assets'
import { functionButtons, homeCardsPlanA, homeCardsPlanB } from '../helper/helper'
import { useNavigate } from 'react-router-dom'
import { PiUserCircle } from 'react-icons/pi'
import { Box, Modal, Typography } from '@mui/material'
import { IoMdCloseCircleOutline } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { store } from '../store'
import { setUser } from '../store/reducers/slices'
import UserService from '../service/user.service'
import TransactionService from '../service/transaction.service'
import PlanService from '../service/plan.service'
import { TRANSACTION_TYPES } from '../helper/enum'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './index.css'
import ReactGA from 'react-ga4'

export default function Home() {
  ReactGA.send({ hitType: 'pageview', page: '/', title: 'Home' })

  const navigate = useNavigate()
  const { user } = useSelector(state => state.user)

  const [ planTabIndex, setPlanTabIndex ] = useState(0)
  const [ planPrice, setPlanPrice ] = useState(null)
  const [ purchasePlan, setPurchasePlan ] = useState(false)
  const [ insufficientBalanceModal, setInsufficientBalanceModal ] = useState(false)
  const [ planInfo, setPlanInfo ] = useState({
    userId: user?._id,
    planDailyEarning: null,
    planPrice: null,
    planValidity: null,
    planStatus: null,
    planTotal: null
  })
  const [ planActivated, setPlanActivated ] = useState(false)

  useEffect(() => {
    if (user) {
      fetchUser(user?._id)
      store.dispatch(setUser({ discountedAmount: 0, actualAmount: 0, upiContent: '' }))
    }
  }, [])

  async function fetchUser(userId) {
    try {
      const response = await UserService.getUser(userId)
      const userData = response.data
      delete userData?.password
      store.dispatch(setUser({ ...userData, token: user?.token }))
    } catch (error) {
      console.log(error.message)
    }
  }

  async function handlePlanPurchase() {
    try {
      const response = await TransactionService.outComeTransactionBalance(user?._id, planPrice, TRANSACTION_TYPES.BUY_PLAN)
      store.dispatch(setUser({ ...user, balance: response?.data?.balance }))
      setPurchasePlan(false)
    } catch (error) {
      console.log(error.message)
    }
  }

  async function createTransaction() {
    try {
      await TransactionService.createTransaction({
        userId: user?._id,
        transactionType: TRANSACTION_TYPES.BUY_PLAN,
        amount: planPrice
      })
    } catch (error) {
      console.log(error.message)
    }
  }

  async function createNewPlan(planDetails) {
    try {
      await PlanService.createPlan(planDetails)
      setPlanInfo({
        ...planInfo,
        userId: user?._id,
        planDailyEarning: null,
        planPrice: null,
        planValidity: null,
        planStatus: null,
        planTotal: null
      })
    } catch (error) {
      console.error(error)
    }
  }

  const PlanContent = [
    {
      tab: homeCardsPlanA
    },
    {
      tab: homeCardsPlanB
    }
  ]

  async function handleBuyPlan() {
    if (planPrice > user?.balance) {
      setPurchasePlan(false)
      setInsufficientBalanceModal(true)
    } else {
      try {
        await handlePlanPurchase()
        await createTransaction()
        await createNewPlan(planInfo)
        setPurchasePlan(false)
        setPlanActivated(true)
      } catch (error) {
        console.error(error)
      }
    }
  }

  const data = [
    { key: 'Register and get 500 for free' },
    { key: 'Minimum recharge amount is 800' },
    { key: 'Minimum withdrawal amount is 2500' },
    { key: 'Daily income Daily Withdrawal' },
    { key: 'Invitation commission 30%' },
    { key: 'You can withdraw Your Total amount after offer end' },
    { key: 'You can\'t use your bonus amount to buy a subscription you can only withdraw it.' }
  ]

  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    gap: 20,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  async function isLogIn(data) {
    if (!user?.token) {
      navigate('/login')
      return
    }
    return data
  }

  return (
      <div className="bg-gradient-to-b from-teal-500 to-white overflow-x-hidden">
        <div className="w-full py-5 px-6 flex flex-row items-center justify-between bg-teal-400">
          <div className="flex items-center gap-8">
            <div className="flex flex-row items-center gap-4">
              <img className="object-contain" src={logoImg} width={100} alt="logo"/>
            </div>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <button className="p-2 rounded-full bg-teal-600" onClick={() => navigate('/profile')}><PiUserCircle color="#fff" size={24}/></button>
          </div>
        </div>
        <div className="my-3 mx-1">
          <Slider {...settings}>
            <div>
              <img className="slider-img" src={sliderImg1} alt={'image'}/>
            </div>
            <div>
              <img className="slider-img" src={sliderImg2} alt={'image'}/>
            </div>
            <div>
              <img className="slider-img" src={sliderImg3} alt={'image'}/>
            </div>
          </Slider>
        </div>
        <div className="w-5/6 py-5 my-10 mx-auto flex flex-row gap-4 flex-wrap shrink-0 justify-around rounded-xl">
          {
            functionButtons.map((button, index) => (
                <button key={index} className="py-3 sm:py-4 md:py-5 bg-white w-28 sm:w-40 md:w-48 lg:w-56 rounded-xl" onClick={() => {
                  if (index === 0) {
                    store.dispatch(setUser({ spinCount: 1 }))
                  }
                  navigate(button.link)
                }}>
                  <p>{button.icon}</p>
                  <h1 className="text-center text-sm md:text-xl font-bold text-black">{button.name}</h1>
                </button>
            ))
          }
        </div>
        <div className="flex flex-row items-center justify-around">
          <button className={`${planTabIndex ? 'py-3 w-2/5 text-xl text-teal-900 font-bold' : 'py-3 w-2/5 text-xl font-bold bg-teal-500 border border-teal-300 rounded-full'}`}
                  onClick={() => setPlanTabIndex(0)}>Plan A
          </button>
          <button className={`${planTabIndex ? 'py-3 w-2/5 text-xl font-bold bg-teal-500 border border-teal-300 rounded-full' : 'py-3 text-teal-900 w-2/5 text-xl font-bold'}`}
                  onClick={() => setPlanTabIndex(1)}>Plan B
          </button>
        </div>
        <div className="mx-auto my-10 w-11/12 flex flex-row flex-wrap shrink-0 items-center justify-around gap-5">
          {
            PlanContent[planTabIndex]?.tab.map((plan, index) => {
              return (
                  <div key={index} className="relative w-80 rounded-xl bg-cover sm:bg-contain bg-no-repeat" style={{ backgroundImage: `url(${plan.bgImg})` }}>
                    {
                      plan.isSpecial ? <div className="absolute top-4 right-4">
                        <img src={specialOfferImg} width={50} height={50} alt={'special-offer'}/>
                      </div> : null
                    }
                    <div className={`px-2 sm:px-3 ${!planTabIndex ? 'py-5 mb-32 sm:mb-40' : 'py-4 mt-32 sm:mt-40'}`}>
                      <div className="bg-white text-xs sm:text-sm mb-2 py-1 text-center rounded-lg">{plan.title}</div>
                      <div className="flex flex-row items-center justify-between gap-2">
                        <div className="w-full">
                          <div className="w-full text-xs sm:text-sm text-center mb-2 bg-white py-1 rounded-lg">Price: ₹{plan.price}</div>
                          <div className="w-full text-xs sm:text-sm text-center bg-white py-1 rounded-lg">Total: ₹{plan.total}</div>
                        </div>
                        <div className="w-full">
                          <div className="w-full text-xs sm:text-sm text-center mb-2 bg-white py-1 rounded-lg">Daily: ₹{plan.daily}</div>
                          <div className="w-full text-xs sm:text-sm text-center bg-white py-1 rounded-lg">Offer end: {plan.endOffer} day</div>
                        </div>
                      </div>
                    </div>
                    <button className="w-full py-2 text-teal-900 bg-teal-400 rounded-b-xl font-bold"
                            onClick={() =>
                                isLogIn(
                                    setPlanPrice(plan?.price),
                                    setPurchasePlan(true),
                                    setPlanInfo({
                                      ...planInfo,
                                      planDailyEarning: plan?.daily,
                                      planPrice: plan?.price,
                                      planValidity: plan?.endOffer,
                                      planStatus: 0,
                                      planTotal: plan?.total
                                    })
                                )}>BUY NOW
                    </button>
                  </div>
              )
            })
          }
        </div>

        {/*notice modal*/}
        <Modal
            open={user?.notice}
            onClose={() => store.dispatch(setUser({ ...user, notice: false }))}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              outline: 'none',
              '& *': {
                outline: 'none'
              }
            }}
        >
          <Box
              className="rounded-xl w-5/6 sm:w-3/6 md:w-3/6 lg:w-1/4"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: '#fff',
                boxShadow: 24
              }}>
            <Typography className="text-center text-teal-900 py-2 bg-teal-300 rounded-t-xl" sx={{ fontWeight: 600 }} id="modal-modal-title" variant="h6" component="h2">
              Bisleri
              <IoMdCloseCircleOutline className="absolute top-2 right-2" size={22} onClick={() => store.dispatch(setUser({ ...user, notice: false }))}/>
            </Typography>
            <div className="px-3 py-4">
              {
                data.map((item, index) => {
                  return (
                      <Typography key={index} id="modal-modal-description" sx={{ mt: 1, fontSize:14, fontWeight: 600, letterSpacing: 0.8 }}>
                        • {item.key}
                      </Typography>
                  )
                })
              }
            </div>
          </Box>
        </Modal>

        {/*purchase modal*/}
        <Modal
            open={purchasePlan}
            onClose={() => setPurchasePlan(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              outline: 'none',
              '& *': {
                outline: 'none'
              }
            }}
        >
          <Box
              className="rounded-xl w-9/12 lg:w-4/12 xl:w-3/12"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: '#fff',
                boxShadow: 24
              }}>
            <Typography className="text-center text-teal-900 py-2 bg-teal-300 rounded-t-xl" sx={{ fontWeight: 600 }} id="modal-modal-title" variant="h6" component="h2">
              Purchase
              <IoMdCloseCircleOutline className="absolute top-2 right-2" size={22} onClick={() => setPurchasePlan(false)}/>
            </Typography>
            <div className="px-3 py-4">
              <Typography className="text-center" sx={{ fontWeight: 500, fontSize: 18, my: 2 }}>Are you sure you want to buy this plan?</Typography>
            </div>
            <div className="flex flex-row items-center justify-around mb-6">
              <button className="py-1 w-2/6 border border-black bg-white text-teal-900 rounded-full" onClick={() => setPurchasePlan(false)}>No</button>
              <button className="py-1 w-2/6 border border-black bg-teal-400 text-white rounded-full" onClick={handleBuyPlan}>Yes</button>
            </div>
          </Box>
        </Modal>

        {/*plan activated modal*/}
        <Modal
            open={planActivated}
            onClose={() => setPlanActivated(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              outline: 'none',
              '& *': {
                outline: 'none'
              }
            }}
        >
          <Box
              className="rounded-xl w-5/6 sm:w-3/6 md:w-3/6 lg:w-1/4"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: '#fff',
                boxShadow: 24
              }}>
            <Typography className="text-center text-teal-900 py-2 bg-teal-300 rounded-t-xl" sx={{ fontWeight: 600 }} id="modal-modal-title" variant="h6" component="h2">
              Plan Activated
              <IoMdCloseCircleOutline className="absolute top-2 right-2" size={22} onClick={() => setPlanActivated(false)}/>
            </Typography>
            <div className="m-6">
              <p className="mx-6 text-center text-lg font-medium">Your plan has been activated now. Thank you for choosing our service, we hope you enjoy your plan.</p>
            </div>

          </Box>
        </Modal>

        {/*insufficient balance*/}
        <Modal
            open={insufficientBalanceModal}
            onClose={() => setInsufficientBalanceModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              outline: 'none',
              '& *': {
                outline: 'none'
              }
            }}
        >
          <Box
              className="rounded-xl w-9/12 lg:w-4/12 xl:w-3/12"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: '#fff',
                boxShadow: 24
              }}>
            <Typography className="text-center text-teal-900 py-2 bg-teal-300 rounded-t-xl" sx={{ fontWeight: 600 }} id="modal-modal-title" variant="h6" component="h2">
              Please Recharge
              <IoMdCloseCircleOutline className="absolute top-2 right-2" size={22} onClick={() => setInsufficientBalanceModal(false)}/>
            </Typography>
            <Typography className="text-center text-red-500" sx={{ fontWeight: 700, fontSize: 18, my: 2 }}>Insufficient Balance</Typography>
            <div className="flex flex-col items-center justify-center">
              <Typography sx={{ fontWeight: 600, fontSize: 16, textAlign: 'center', width: '70%', mb: 2 }}>You need more {planPrice - user?.balance} amount in your balance for activate this plan
                please
                recharge.</Typography>
              <button className="py-1 mb-6 w-4/6 mx-auto bg-teal-400 border border-teal-200 text-teal-900 font-bold rounded-full" onClick={() => navigate('/recharge')}>Recharge</button>
            </div>
          </Box>
        </Modal>
      </div>
  )
}
