import axiosInstance from './gAxios'

export default class TransactionService{
  static async getTransactions(userId) {
    try {
        const response = await axiosInstance.get(`/v1/transactions/${userId}`)
      return response.data
    } catch (error) {
      console.log(error)
      return []
    }
  }

  static async createTransaction(transactionData) {
    try {
      const response = await axiosInstance.post('/v1/transaction/create', transactionData)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  static async outComeTransactionBalance(userId, amount, type) {
    try {
      const response = await axiosInstance.post('/v1/transaction/outcome-Balance', { userId, amount, type })
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  static async inComeTransactionBalance(userId, amount) {
    try {
      const response = await axiosInstance.post('/v1/transaction/income-balance', { userId, amount })
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
}
