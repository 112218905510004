import React from 'react'
import { IoWalletOutline } from 'react-icons/io5'
import { PiHandWithdraw, PiUserCirclePlus } from 'react-icons/pi'
import { TbTransactionRupee } from 'react-icons/tb'
import { cardImg1, cardImg2 } from '../assets'

export const functionButtons = [
  {
    name: 'Recharge',
    icon: <IoWalletOutline className="mb-2 mx-auto text-3xl md:text-4xl text-teal-800"/>,
    link: '/recharge'
  },
  {
    name: 'Withdraw',
    icon: <PiHandWithdraw className="mb-2 mx-auto text-3xl md:text-4xl text-teal-800"/>,
    link: '/withdraw'
  },
  {
    name: 'Transactions',
    icon: <TbTransactionRupee className="mb-2 mx-auto text-3xl md:text-4xl text-teal-800"/>,
    link: '/transactions'
  },
  {
    name: 'Invite',
    icon: <PiUserCirclePlus className="mb-2 mx-auto text-3xl md:text-4xl text-teal-800"/>,
    link: '/invite'
  }
]

export const homeCardsPlanA = [
  {
    title: 'Daily Earnings Daily Withdrawals',
    price: 990,
    daily: 250,
    total: 6250,
    endOffer: 25,
    bgImg: cardImg1
  },
  {
    title: 'Daily Earnings Daily Withdrawals',
    price: 1990,
    daily: 850,
    total: 17000,
    endOffer: 20,
    bgImg: cardImg1
  },
  {
    title: 'Daily Earnings Daily Withdrawals',
    price: 4990,
    daily: 2150,
    total: 33750,
    endOffer: 15,
    bgImg: cardImg1
  },
  {
    title: 'Daily Earnings Daily Withdrawals',
    price: 9990,
    daily: 3250,
    total: 48750,
    endOffer: 15,
    bgImg: cardImg1
  },
  {
    title: 'Daily Earnings Daily Withdrawals',
    price: 19990,
    daily: 4500,
    total: 67500,
    endOffer: 15,
    bgImg: cardImg1
  }
]

export const homeCardsPlanB = [
  {
    title: 'Daily Earnings Daily Withdrawals',
    price: 6000,
    daily: 4000,
    total: 28000,
    endOffer: 7,
    bgImg: cardImg2
  },
  {
    title: 'Daily Earnings Daily Withdrawals',
    price: 9000,
    daily: 6000,
    total: 42000,
    endOffer: 7,
    bgImg: cardImg2
  },
  {
    title: 'Daily Earnings Daily Withdrawals',
    price: 20000,
    daily: 12000,
    total: 60000,
    endOffer: 5,
    bgImg: cardImg2,
    isSpecial: true
  },
  {
    title: 'Daily Earnings Daily Withdrawals',
    price: 28000,
    daily: 16000,
    total: 80000,
    endOffer: 5,
    bgImg: cardImg2,
    isSpecial: true
  }
]

export function callOnceInADay(yourFunction) {
  const now = new Date()
  const targetTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 9, 0, 0)
  let delay = targetTime.getTime() - now.getTime()

  if (delay < 0) {
    targetTime.setDate(targetTime.getDate() + 1)
    delay = targetTime.getTime() - now.getTime()
  }

  setTimeout(yourFunction, delay)
}
