import React from 'react'
import { logoImg } from '../assets'
import { useNavigate } from 'react-router-dom'
import { PiUserCircle, PiArrowLeftBold } from 'react-icons/pi'
import { store } from '../store'
import { setUser } from '../store/reducers/slices'

export default function Header({ navigation, updateRedux }) {

  const navigate = useNavigate()

  function handleNavigation(){
    if(updateRedux){
      store.dispatch(setUser({ discountedAmount: 0, actualAmount: 0, upiContent: '' }))
    }
    navigate(navigation)
  }

  return (
      <div className="w-full py-5 px-6 flex flex-row items-center justify-between bg-teal-400">
        <div className="flex items-center gap-8">
          <div onClick={handleNavigation} className="flex flex-row items-center gap-4">
            <PiArrowLeftBold color="#000" size={30}/>
            {/*<img className="object-contain" src={logoImg} width={100} alt="logo"/>*/}
          </div>
        </div>
        <div className="flex flex-row gap-2 items-center">
          <button className="p-2 rounded-full bg-teal-600" onClick={() => navigate('/profile')}><PiUserCircle color="#fff" size={24}/></button>
        </div>
      </div>
  )
}
