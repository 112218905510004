import React, { useState } from 'react'
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material'
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md'
import { Bottels } from '../assets'
import { useNavigate } from 'react-router-dom'
import UserService from '../service/user.service'
import { SERVER_UPDATE } from '../store/types'
import Swal from 'sweetalert2'
import { store } from '../store'
import { setUser } from '../store/reducers/slices'
import { useSelector } from 'react-redux'
import ReactGA from 'react-ga4'

export default function Login() {
  ReactGA.send({ hitType: 'pageview', page: '/login', title: 'Login' })

  const navigate = useNavigate()

  const [ showPassword, setShowPassword ] = useState(false)
  const [ loginInfo, setLoginInfo ] = useState({
    mobile: null,
    password: ''
  })

  async function loginUser() {
    try {
      if (!loginInfo.mobile) return Swal.fire({ text: "Please Enter Mobile!", icon: "warning" });
      if (!loginInfo.password) return Swal.fire({ text: "Please Enter Password!", icon: "warning" });

      const response = await UserService.login(loginInfo)
      if (!response) return Swal.fire({ text: "User not found!", icon: "warning" });

      const userData = { ...response.user, token: response.token, notice: true };
      delete userData.password
      store.dispatch(setUser(userData))

      localStorage.setItem('token', response.token)
      await navigate('/home')
      setLoginInfo({ ...loginInfo, mobile: null, password: '' })
    } catch (error) {
      console.log(error.message)
    }
  }

  return (
      <div className="h-dvh overflow-y-hidden bg-gradient-to-b from-teal-500 to-white flex items-center justify-center">
        <div className="flex flex-col gap-4 w-5/6 sm:w-4/6 lg:w-2/6 bg-white p-8 rounded-xl">
          <img className="mx-auto" onClick={() => navigate('/')} src={Bottels} width={150} height={150} alt="bisleri logo"/>
          <Typography className="text-center mb-2 text-teal-500" sx={{ fontSize: 24, fontWeight: 900 }}>Bisleri</Typography>
          <TextField
              id="outlined-basic"
              label="Mobile Number"
              variant="outlined"
              type="number"
              value={loginInfo.mobile}
              onChange={(e) => setLoginInfo({ ...loginInfo, mobile: e.target.value })}
          />
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={loginInfo.password}
                onChange={(e) => setLoginInfo({ ...loginInfo, password: e.target.value })}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(prevState => !prevState)}
                        edge="end"
                    >
                      {showPassword ? <MdOutlineVisibilityOff/> : <MdOutlineVisibility/>}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
            />
          </FormControl>
          <Button variant="contained"
                  sx={{
                    bgcolor: 'rgb(94 234 212)',
                    padding: 1,
                    color: '#000',
                    fontSize: 14,
                    fontWeight: 600,
                    boxShadow: 'none',
                    textTransform: 'capitalize',
                    '&:hover': {
                      bgcolor: 'rgb(45 212 191)',
                      color: '#000'
                    }
                  }}
                  onClick={loginUser}
          >Login</Button>
          <Button variant="contained"
                  sx={{
                    bgcolor: 'rgb(94 234 212)',
                    padding: 1,
                    color: '#000',
                    fontSize: 14,
                    fontWeight: 600,
                    boxShadow: 'none',
                    textTransform: 'capitalize',
                    '&:hover': {
                      bgcolor: 'rgb(45 212 191)',
                      color: '#000'
                    }
                  }}
                  onClick={() => navigate('/register')}
          >Register</Button>
        </div>
      </div>
  )
}
