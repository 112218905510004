import React, { useEffect, useState } from 'react'
import { logoImg } from '../assets'
import UpiIdsService from '../service/upiIds.service'
import { useNavigate } from 'react-router-dom'
import Header from '../Components/header'
import { useSelector } from 'react-redux'
import { store } from '../store'
import { setUser } from '../store/reducers/slices'
import ReactGA from 'react-ga4'

export default function PaymentOptions() {
  ReactGA.send({ hitType: 'pageview', page: '/payment-options', title: 'Payment Options' })

  const navigate = useNavigate()
  const { user } = useSelector(state => state.user)

  const [ upiIds, setUpiIds ] = useState([{
    pa: "vyapar.6578532@upi",
    pn: "Bislerii",
    cu: "INR",
    am: parseFloat(user?.discountedAmount ? user?.discountedAmount : user?.actualAmount).toFixed(2),
    isApp: false
  }])

  useEffect(() => {
    // fetchUpiIds(user?.discountedAmount ? user?.discountedAmount : user?.actualAmount)
  }, [])

  // async function fetchUpiIds(value) {
  //   try {
  //     const response = await UpiIdsService.getUpiIds(value)
  //     setUpiIds(response.data)
  //   } catch (error) {
  //     console.log(error.message)
  //   }
  // }

  return (
      <div className="h-screen bg-gradient-to-b from-teal-500 to-white">
        <Header navigation={'/'} updateRedux={true}/>
        <div className="overflow-y-scroll flex flex-col items-center mt-10">
          <div className="flex flex-col gap-3 w-5/6 sm:w-4/6 lg:w-2/6">
            {
              upiIds?.map((upi, index) => {
                return (
                    <div key={index} className="cursor-pointer">
                      {
                        !upi?.isApp ?
                            <div className="flex flex-row items-center gap-5 bg-white w-full p-4 rounded-xl border border-teal-300"
                                 onClick={() => {
                                   store.dispatch(setUser({ upiContent: `upi://pay?pa=${upi?.pa}&pn=${upi?.pn}&cu=${upi?.cu}&am=${upi?.am}` }))
                                   navigate('/confirm-recharge')
                                 }}>
                              <img className="rounded-xl w-1/4" src={logoImg} alt={'logo img'}/>
                              <p className="text-xl lg:text-2xl font-bold">Pay with QR</p>
                            </div>
                            : null
                      }
                      {/*      <Link to={upi?.vpa} target={'_blank'} className="flex flex-row items-center gap-5 bg-white w-full p-4 rounded-xl border border-teal-300"*/}
                      {/*            onClick={() => window.open(upi?.vpa)}>*/}
                      {/*        <img className="rounded-xl w-1/4" src={logoImg} alt={'logo img'}/>*/}
                      {/*        <p className="text-xl lg:text-2xl font-bold">Pay with Upi App</p>*/}
                      {/*      </Link>*/}
                    </div>

                )
              })
            }
          </div>
        </div>
      </div>
  )
}
