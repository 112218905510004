import React, { useEffect, useState } from 'react'
import { Button, TextField, Typography } from '@mui/material'
import Header from '../Components/header'
import TransactionService from '../service/transaction.service'
import { useSelector } from 'react-redux'
import { TRANSACTION_TYPES } from '../helper/enum'
import { useNavigate } from 'react-router-dom'
import WithdrawService from '../service/withraw.service'
import Swal from 'sweetalert2'
import ReactGA from 'react-ga4'

export default function Withdraw() {
  ReactGA.send({ hitType: 'pageview', page: '/withdraw', title: 'Withdraw' })

  const { user } = useSelector(state => state.user)
  const navigate = useNavigate()

  const [ withdrawalInfo, setWithdrawalInfo ] = useState({
    userId: user?._id,
    mobileNumber: null,
    fullName: '',
    bankAccount: null,
    bankName: '',
    ifscCode: '',
    amount: null,
    isRequested: true
  })
  const [ isEnabled, setIsEnabled ] = useState(false)

  useEffect(() => {
    const currentTime = new Date()
    const startTime = new Date()
    startTime.setHours(10)
    startTime.setMinutes(29)

    const endTime = new Date()
    endTime.setHours(18)
    endTime.setMinutes(0)

    if (currentTime >= startTime && currentTime <= endTime) {
      setIsEnabled(true)
    } else {
      setIsEnabled(false)
    }
  }, [])

  async function createWithdrawRequest(withdrawInfo) {
    try {
      await WithdrawService.createWithdraw(withdrawInfo)
      Swal.fire({ text: 'Withdrawal request submitted successfully.', icon: 'warning' })

      setWithdrawalInfo({
        userId: user?._id,
        mobileNumber: null,
        fullName: '',
        bankAccount: null,
        bankName: '',
        ifscCode: '',
        amount: null,
        isRequested: true
      })
      navigate('/home')
    } catch (error) {
      console.log(error.message)
    }
  }

  const data = [
    { key: 'Minimum withdrawal: 2500/-' },
    { key: 'Withdrawal time 10:30 AM- 02:00 PM' },
    { key: 'Fill in the bank account information and IFSC code correctly' },
    { key: 'Same day withdrawals will be credited  to the account on the same day' }
  ]

  async function createTransaction() {
    try {
      await TransactionService.createTransaction({
        userId: user?._id,
        transactionType: TRANSACTION_TYPES.WITHDRAW_IN_PROCESS,
        amount: withdrawalInfo?.amount
      })
      Swal.fire({ text: "Your withdrawal request successfully submitted", icon: "success" })
    } catch (error) {
      console.log(error.message)
    }
  }

  async function handleWithdrawal() {
    if (!user?.token) {
      navigate('/login')
      return
    } else {
      if (isEnabled) {
        if (!withdrawalInfo?.mobileNumber) return Swal.fire({ text: "Please enter phone number!", icon: "warning" })
        if (!withdrawalInfo?.fullName) return Swal.fire({ text: "Please enter full name!", icon: "warning" })
        if (!withdrawalInfo?.bankAccount) return Swal.fire({ text: "Please enter bank account number!", icon: "warning" })
        if (!withdrawalInfo?.bankName) return Swal.fire({ text: "Please enter bank name!", icon: "warning" })
        if (!withdrawalInfo?.ifscCode) return Swal.fire({ text: "Please enter IFSC code!", icon: "warning" })
        if (!withdrawalInfo?.amount) return Swal.fire({ text: "Please enter amount!", icon: "warning" })
        if (withdrawalInfo?.amount < 2500) return Swal.fire({ text: "Minimum withdrawal amount is 2500/-", icon: "warning" })
        if (user?.balance < withdrawalInfo?.amount) return Swal.fire({ text: "Insufficient Balance to withdraw", icon: "warning" })

        await createWithdrawRequest(withdrawalInfo)
        await createTransaction()
        navigate('Home')
      } else {
        Swal.fire({ text: "Withdrawal is allowed within 10:30 am to 2:00 pm", icon: "warning" })
      }
    }
  }

  return (
      <div className="h-screen bg-gradient-to-b from-teal-500 to-white">
        <Header navigation={'/'}/>
        <div className="flex flex-col items-center justify-center mt-12">
          <div className="p-6 flex flex-col gap-3 w-5/6 sm:w-4/6 lg:w-2/6 bg-white rounded-xl shadow-xl">
            <TextField
                id="outlined-basic"
                label="Mobile Number"
                variant="outlined"
                type="number"
                value={withdrawalInfo.mobileNumber}
                onChange={(e) => setWithdrawalInfo({ ...withdrawalInfo, mobileNumber: e.target.value })}
            />
            <TextField
                id="outlined-basic"
                label="Full Name"
                variant="outlined"
                value={withdrawalInfo.fullName}
                onChange={(e) => setWithdrawalInfo({ ...withdrawalInfo, fullName: e.target.value })}
            />
            <TextField
                id="outlined-basic"
                label="Bank Account"
                type="number"
                variant="outlined"
                value={withdrawalInfo.bankAccount}
                onChange={(e) => setWithdrawalInfo({ ...withdrawalInfo, bankAccount: e.target.value })}
            />
            <TextField
                id="outlined-basic"
                label="Bank Name"
                variant="outlined"
                value={withdrawalInfo.bankName}
                onChange={(e) => setWithdrawalInfo({ ...withdrawalInfo, bankName: e.target.value })}
            />
            <TextField
                id="outlined-basic"
                label="IFSC Code"
                variant="outlined"
                value={withdrawalInfo.ifscCode}
                onChange={(e) => setWithdrawalInfo({ ...withdrawalInfo, ifscCode: e.target.value })}
            />
            <TextField
                id="outlined-basic"
                label="Amount"
                variant="outlined"
                type="number"
                value={withdrawalInfo.amount}
                onChange={(e) => setWithdrawalInfo({ ...withdrawalInfo, amount: e.target.value })}
            />
            <Button
                sx={{
                  bgcolor: 'rgb(94 234 212)',
                  marginTop: 4,
                  padding: 1,
                  color: '#000',
                  fontSize: 14,
                  fontWeight: 600,
                  boxShadow: 'none',
                  textTransform: 'capitalize',
                  '&:hover': {
                    bgcolor: 'rgb(45 212 191)',
                    color: '#000'
                  }
                }}
                onClick={handleWithdrawal}
            >Confirm Withdrawal</Button>
          </div>
          <div className="bg-white mb-3 w-5/6 sm:w-4/6 lg:w-2/6 rounded-xl p-5 mt-8 border border-teal-300">
            {
              data.map((item, index) => (
                  <Typography key={index} className="flex items-center gap-2">
                    • {item.key}
                  </Typography>
              ))
            }
          </div>
        </div>
      </div>
  )
}
