import React, { useEffect, useState } from 'react'
import Header from '../Components/header'
import { Box, CircularProgress, Step, StepLabel, Stepper, Tab, Tabs } from '@mui/material'
import { MdLockOpen, MdLockOutline } from 'react-icons/md'
import { FaMoneyBillAlt } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import PlanService from '../service/plan.service'
import { useNavigate } from 'react-router-dom'
import './index.css'
import ReactGA from 'react-ga4'

export default function TrackPlan() {
  ReactGA.send({ hitType: 'pageview', page: '/track-plan', title: 'Track Plan' })
  const navigate = useNavigate()
  const { user } = useSelector(state => state.user)

  const [ planData, setPlanData ] = useState([])
  const [ loading, setLoading ] = useState(false)
  const [ planTabIndex, setPlanTabIndex ] = useState(0)
  const [ activeStep, setActiveStep ] = useState(0)

  useEffect(() => {
    fetchPlanData(user?._id)
  }, [])

  async function fetchPlanData(userId) {
    try {
      setLoading(true)
      const response = await PlanService.getPlans(userId)
      const filteredData = response?.data.filter(item => item.planStatus !== item.planValidity)
      setActiveStep(filteredData[0]?.planStatus - 1)
      setPlanData(filteredData)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const totalEarning = planData.reduce((acc, current) => {
    return acc + (current.planStatus !== current.planValidity ? current.planStatus * current.planDailyEarning : 0)
  }, 0)

  return (
      <div className="h-screen bg-gradient-to-b from-teal-500 to-white">
        <Header navigation={'/profile'}/>
        {
          loading ? (
                  <div className="flex justify-center mt-56">
                    <CircularProgress sx={{ color: '#18928c' }}/>
                  </div>) :
              planData.length ?
                  <div>
                    <div className="mt-6 mb-10 mr-6 flex flex-row items-center justify-end">
                      <p className="mr-3 text-sm sm:text-lg font-medium">Total Earning: </p>
                      <div className="py-1 w-40 rounded-lg text-center border border-teal-500 bg-white text-lg font-bold">{totalEarning}</div>
                    </div>
                    <div className="w-5/6 sm:w-4/6 lg:w-3/6 mx-auto flex items-center justify-center">
                      <Box sx={{ width: '100%', marginBottom: 10 }}>
                        <div className="mb-6 flex flex-row overflow-x-auto items-center gap-3 ">
                          <Tabs
                              value={planTabIndex}
                              variant="scrollable"
                              onChange={(event, newValue) => {
                                setActiveStep(planData[newValue]?.planStatus - 1)
                                setPlanTabIndex(newValue)
                              }}
                          >
                            {
                              Array.from(Array(planData?.length).keys(), x => x + 1).map((index) => {
                                return (
                                    <Tab
                                        key={index}
                                        sx={{
                                          bgcolor: 'rgb(45 212 191)',
                                          color: 'white',
                                          border: '2px solid rgb(94 234 212)',
                                          borderRadius: '50px',
                                          paddingX: 4,
                                          marginRight: 2,
                                          '& .MuiTabs-indicator': {
                                            display: 'none'
                                          }
                                        }}
                                        label={`Plan ${index}`}/>
                                )
                              })
                            }
                          </Tabs>
                        </div>
                        <Stepper activeStep={activeStep} orientation="vertical">
                          {
                            Array.from(Array(planData[planTabIndex]?.planValidity).keys(), x => x + 1).map((index) => {
                              return (
                                  <Step key={index}>
                                    <StepLabel sx={{ padding: 0 }}>
                                      <div className="flex flex-row items-center gap-3 ml-4">
                                        {
                                          planData[planTabIndex]?.planStatus >= index ? <MdLockOpen size={24}/> :
                                              <MdLockOutline size={24}/>
                                        }
                                        <div className="flex flex-row items-center bg-white pl-4 text-base sm:text-lg font-medium border border-teal-500 rounded-xl py-6 w-full">
                                          <FaMoneyBillAlt className="mr-3" size={24}/>
                                          Day {index} Earning
                                        </div>
                                      </div>
                                    </StepLabel>
                                  </Step>
                              )
                            })}
                        </Stepper>
                      </Box>
                    </div>
                  </div> : <div className="w-full flex items-center justify-center">
                    <div className="bg-white text-center text-lg mx-4 font-medium py-6 px-4 rounded-xl border border-teal-300 mt-20" style={{width: 450}}>
                      <p className="font-bold mb-3">Activate Your Plan Today!</p>
                      <p className="text-xs sm:text-sm mb-1">It looks like you don't have an active plan at the moment.</p>
                      <p className="text-xs sm:text-sm mb-4">Don't miss out! Purchase plans</p>
                      <div className="cursor-pointer underline text-teal-700" onClick={() => navigate('/')}>Choose Your plan?</div>
                    </div>
                  </div>
        }
      </div>
  )
}
