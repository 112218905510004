import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './Pages/Home'
import Login from './Pages/Login'
import Register from './Pages/Register'
import Transaction from './Pages/Transaction'
import Withdraw from './Pages/Withdraw'
import Profile from './Pages/Profile'
import Invite from './Pages/Invite'
import Recharge from './Pages/Recharge'
import PaymentOptions from './Pages/PaymentOptions'
import ConfirmRecharge from './Pages/ConfirmRecharge'
import TrackPlan from './Pages/TrackPlan'
import { callOnceInADay } from '../src/helper/helper'
import { useSelector } from 'react-redux'
import TransactionService from './service/transaction.service'
import PlanService from './service/plan.service'
import { TRANSACTION_TYPES } from './helper/enum'
import ReactGA from 'react-ga4'
import './index.css'

function App() {
  const { user } = useSelector(state => state.user)

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      ReactGA.initialize('G-NPBJRN7NQK')
    }
  }, [])

  async function giveAppEarning(userId) {
    try {
      const response = await PlanService.getPlans(userId)
      const activePlan = response?.data.filter(item => item.planStatus !== item.planValidity)
      if (activePlan.length) {
        activePlan.forEach(async (plan) => {
          await TransactionService.inComeTransactionBalance(userId, plan.planDailyEarning)
          await TransactionService.createTransaction({
            userId: userId,
            transactionType: TRANSACTION_TYPES.APP_EARNING,
            amount: plan.planDailyEarning
          })
          await PlanService.updatePlan({
            planId: plan._id,
            planStatus: plan.planStatus + 1
          })
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function yourFunction() {
    try {
      await giveAppEarning(user?._id)
    } catch (error) {
      console.log(error.message)
    }
  }

  useEffect(() => {
    callOnceInADay(yourFunction)
  }, [])

  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/login" element={<Login/>}/>
          <Route path="/register" element={<Register/>}/>
          <Route path="/transactions" element={<Transaction/>}/>
          <Route path="/withdraw" element={<Withdraw/>}/>
          <Route path="/profile" element={<Profile/>}/>
          <Route path="/invite" element={<Invite/>}/>
          <Route path="/recharge" element={<Recharge/>}/>
          <Route path="/payment-options" element={<PaymentOptions/>}/>
          <Route path="/confirm-recharge" element={<ConfirmRecharge/>}/>
          <Route path="/track-plan" element={<TrackPlan/>}/>
          <Route path="*" element={<Home/>}/>
        </Routes>
      </BrowserRouter>
  )
}

export default App
