import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  user: null,
  loading: false,
  error: null
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = { ...state.user, ...action.payload }
      return state
    },
    removeUser: (state) => {
      state.user = null
      return state
    }
  }
  // extraReducers: {}
})
export const { setUser, removeUser } = userSlice.actions

export default userSlice.reducer
