import axiosInstance from './gAxios'

export default class UserService {
  static async getUser(userId){
    try {
      const response = await axiosInstance.get(`/v1/users/${userId}`);
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  static async login(loginInfo) {
    try {
      const response = await axiosInstance.post('/v1/users/login', loginInfo)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  static async register(registerInfo) {
    try {
      const response = await axiosInstance.post('/v1/users/register', registerInfo)

      return response.data
    } catch (error) {
      console.log(error)
    }
  }
}
