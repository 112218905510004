import axiosInstance from './gAxios'

export default class WithdrawService {
  static async getWithdrawals() {
    try {
      const response = await axiosInstance.get('/v1/withdraw/get')
      return response.data
    } catch (error) {
      console.log(error)
    }
  }

  static async createWithdraw(withdrawInfo) {
    try {
      const response = await axiosInstance.post('/v1/withdraw/create', withdrawInfo)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
}
