import React from 'react'
import { MdOutlineCloudUpload } from 'react-icons/md'
import { CloudinaryUploadWidget } from 'react-cloudinary-uploader'
import Swal from 'sweetalert2'

export default function Uploader({ setUrl, url }) {

  const handleUploadSuccess = (info) => {
    console.log('info :: ', info, info?.secure_url)
    setUrl(info?.secure_url)
  }

  const handleUploadFailure = (error) => {
    Swal.fire({ text: error.message, icon: 'error' })
    console.error('Upload error:', error)
  }

  const pictureUploaderOptions = {
    clientAllowedFormats: [ 'jpg', 'jpeg', 'png', 'gif' ],
    resourceType: 'image',
    maxFileSize: 10000000,
    folder: 'transactions',
    sources: [ 'local', 'camera' ]
  }

  return <div className="w-full mt-6 py-3 flex flex-row items-center justify-center bg-teal-400 rounded-lg">
    <CloudinaryUploadWidget
        cloudName="dsyzty2lb"
        uploadPreset="Bisleri"
        options={pictureUploaderOptions}
        onUploadSuccess={handleUploadSuccess}
        onUploadFailure={handleUploadFailure}
    >
      <button className="gap-2 flex justify-center items-center font-medium">
        <MdOutlineCloudUpload type="file" size={24}/>{url ? 'Image Uploded' : 'Upload ScreenShot'}
      </button>
    </CloudinaryUploadWidget>
  </div>
}
