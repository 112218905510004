import axiosInstance from './gAxios'

export default class RechargeService {
  static async createRecharge(rechargeInfo) {
    try {
      const response = await axiosInstance.post('/v1/recharge/create', rechargeInfo)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
}
