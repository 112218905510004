import React, { useState } from 'react'
import { Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField, Typography } from '@mui/material'
import { MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md'
import { Bottels } from '../assets'
import TransactionService from '../service/transaction.service'
import UserService from '../service/user.service'
import { TRANSACTION_TYPES } from '../helper/enum'
import { useNavigate } from 'react-router-dom'
import { store } from '../store'
import { setUser } from '../store/reducers/slices'
import Swal from 'sweetalert2'
import ReactGA from 'react-ga4'

export default function Register() {
  ReactGA.send({ hitType: 'pageview', page: '/register', title: 'Register' })

  const navigate = useNavigate()

  const [ showPassword, setShowPassword ] = useState({
    password: false,
    confirmPassword: false
  })
  const [ registerInfo, setRegisterInfo ] = useState({
    mobile: null,
    password: '',
    balance: 0,
    bonus: 500,
    referralInvitationCode: ''
  })
  const [ confirmPassword, setConfirmPassword ] = useState('')

  async function createTransaction(userId, amount){
    try {
      await TransactionService.createTransaction({
        userId: userId,
        transactionType: TRANSACTION_TYPES.BONUS_EARNING,
        amount: amount
      })
    } catch (error) {
      console.log(error.message)
    }
  }

  async function registerUser() {
    try {
      if (!registerInfo.mobile) return Swal.fire({ text: "Please Enter mobile!", icon: "warning" })
      if (!registerInfo.password) return Swal.fire({ text: "Please Enter password!", icon: "warning" })
      if (!confirmPassword) return Swal.fire({ text: "Please Enter confirm password!", icon: "warning" })
      if (registerInfo.password !== confirmPassword) return Swal.fire({ text: "Passwords do not match!", icon: "warning" })

      const response = await UserService.register(registerInfo)
      if (!response) return Swal.fire({ text: "Invalid token or registration failed. Please try again.", icon: "warning" })
      if (response) {
        if(response.invitorUser){
          await createTransaction(response.invitorUser._id, 300)
        }
        await createTransaction(response.data._id, 500)
      }
      const loginResponse = await UserService.login({ mobile: registerInfo?.mobile, password: registerInfo?.password })
      if (!loginResponse) return Swal.fire({ text: "User not found!", icon: "warning" })

      const userData = { ...loginResponse.user, token: loginResponse.token, notice: true };
      delete userData.password
      store.dispatch(setUser(userData))

      localStorage.setItem('token', response.token)
      await navigate('/home')

      setRegisterInfo({ ...registerInfo, mobile: null, password: '', referralInvitationCode: '' })
      setConfirmPassword('')
    } catch (error) {
      console.log(error.message)
    }
  }

  return (
      <div className="h-dvh overflow-y-hidden bg-gradient-to-b from-teal-500 to-white flex items-center justify-center">
        <div className="flex flex-col gap-4 w-5/6 sm:w-4/6 lg:w-2/6 bg-white p-8 rounded-xl">
          <img className="mx-auto" onClick={() => navigate('/')} src={Bottels} width={150} height={150} alt="bisleri logo"/>
          <Typography className="text-center mb-2 text-teal-500" sx={{ fontSize: 24, fontWeight: 900 }}>Bisleri</Typography>
          <TextField
              id="outlined-basic"
              required={true}
              label="Mobile Number"
              variant="outlined"
              type="number"
              value={registerInfo.mobile}
              onChange={(e) => setRegisterInfo({ ...registerInfo, mobile: e.target.value })}
          />
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
            <OutlinedInput
                id="outlined-adornment-password"
                required={true}
                type={showPassword.password ? 'text' : 'password'}
                value={registerInfo.password}
                onChange={(e) => setRegisterInfo({ ...registerInfo, password: e.target.value })}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(prevState => ({ ...showPassword, password: !prevState.password }))}
                        edge="end"
                    >
                      {showPassword ? <MdOutlineVisibilityOff/> : <MdOutlineVisibility/>}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
            />
          </FormControl>
          <FormControl variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
            <OutlinedInput
                id="outlined-adornment-password"
                required={true}
                type={showPassword.confirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(prevState => ({ ...showPassword, confirmPassword: !prevState.confirmPassword }))}
                        edge="end"
                    >
                      {showPassword ? <MdOutlineVisibilityOff/> : <MdOutlineVisibility/>}
                    </IconButton>
                  </InputAdornment>
                }
                label="Confirm Password"
            />
          </FormControl>
          <TextField
              id="outlined-basic"
              label="Invite Code"
              variant="outlined"
              value={registerInfo.referralInvitationCode}
              onChange={(e) => setRegisterInfo({ ...registerInfo, referralInvitationCode: e.target.value })}
          />
          <Button variant="contained"
                  sx={{
                    bgcolor: 'rgb(94 234 212)',
                    padding: 1,
                    color: '#000',
                    fontSize: 14,
                    fontWeight: 600,
                    boxShadow: 'none',
                    textTransform: 'capitalize',
                    '&:hover': {
                      bgcolor: 'rgb(45 212 191)',
                      color: '#000'
                    }
                  }}
                  onClick={registerUser}
          >Register</Button>
        </div>
      </div>
  )
}
