import React, { useState } from 'react'
import Header from '../Components/header'
import { Bottels } from '../assets'
import { Button, TextField } from '@mui/material'
import { useSelector } from 'react-redux'
import ReactGA from 'react-ga4'

export default function Invite() {
  ReactGA.send({ hitType: 'pageview', page: '/invite', title: 'Invite' })

  const { user } = useSelector(state => state.user)

  return (
      <div className="h-screen bg-gradient-to-b from-teal-500 to-white">
        <Header navigation={'/'}/>
        <div className="flex flex-col items-center">
          <div className="relative bg-white shadow-xl mt-10 rounded-xl w-5/6 sm:w-4/6 lg:w-2/6">
            <img className="my-10 mx-auto" src={Bottels} width={500} height={350} alt={'bottels'}/>
            <div className="absolute rounded-xl top-0 w-full h-full bg-[#E1E1E1AA]">
              <p className="ml-4 mt-6 text-2xl sm:text-4xl font-bold"><span className="text-red-500">25%</span> commission for inviting</p>
            </div>
          </div>
          <div className="mt-8 w-5/6 sm:w-4/6 lg:w-2/6">
            <p className="text-xl font-bold">Invitation Code: </p>
            <div className="relative w-full mt-3">
              <TextField
                  className="w-full"
                  id="outlined-basic"
                  label="InvitationCode"
                  variant="outlined"
                  value={user?.invitationCode}
              />
              <Button
                  className="w-1/6"
                  sx={{
                    bgcolor: '#000',
                    padding: 1,
                    position: 'absolute',
                    top: 6,
                    right: 8,
                    color: '#fff',
                    fontSize: 16,
                    fontWeight: 600,
                    boxShadow: 'none',
                    textTransform: 'capitalize',
                    '&:hover': {
                      bgcolor: '#000',
                      color: '#fff'
                    }
                  }}
                  onClick={() => navigator.clipboard.writeText(user?.invitationCode)}
              >Copy</Button>
            </div>
          </div>
        </div>
      </div>
  )
}
