import axiosInstance from './gAxios'

export default class PlanService{
  static async getPlans(userId){
    try {
      const response = await axiosInstance.get(`/v1/plan/get/${userId}`)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
  
  static async createPlan(planInfo){
    try {
      const response = await axiosInstance.post('/v1/plan/create', planInfo)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
  
  static async updatePlan(planId, planDetails){
    try {
      const response = await axiosInstance.put('/v1/plan/update', planId, planDetails)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
  
  static async deletePlan(userId){
    try {
      const response = await axiosInstance.delete(`/v1/plan/${userId}`)
      return response.data
    } catch (error) {
      console.log(error)
    }
  }
}
