import React, { useState } from 'react'
import Header from '../Components/header'
import { Button, TextField } from '@mui/material'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Uploader from '../Components/uploader'
import QrSvg from '@wojtekmaj/react-qr-svg'
import RechargeService from '../service/recharge.service'
import { store } from '../store'
import { setUser } from '../store/reducers/slices'
import Swal from 'sweetalert2'
import ReactGA from 'react-ga4'

export default function ConfirmRecharge() {
  ReactGA.send({ hitType: 'pageview', page: '/confirm-recharge', title: 'Confirm Recharge' })

  const navigate = useNavigate()
  const { user } = useSelector(state => state.user)

  const [ rechargeInfo, setRechargeInfo ] = useState({
    userId: user?._id,
    mobile: user?.mobile,
    imageUrl: '',
    UTRNumber: null,
    amount: user?.actualAmount,
    isRecharged: true,
    isDeleted: false
  })

  async function createRechargeRequest() {
    if (!user) {
      navigate('/login')
      return
    } else {
      try {
        const response = await RechargeService.createRecharge(rechargeInfo)
        if (!response) return Swal.fire({ text: 'Something went wrong', icon: 'warning' })
        Swal.fire({ text: 'Recharge request sent successfully!', icon: 'success' })
        store.dispatch(setUser({ rechargeAmount: null, upiContent: '' }))
        navigate('/dashboard')
      } catch (error) {
        console.log(error)
        Swal.fire({ text: 'Failed to send recharge request!', icon: 'error' })
      }
    }
  }

  return (
      <div className="h-screen bg-gradient-to-b from-teal-500 to-white">
        <Header navigation={'/payment-options'}/>
        <div className="overflow-y-scroll flex flex-col items-center mt-10">
          <div className="flex flex-col gap-3 w-5/6 sm:w-4/6 lg:w-2/6">
            <div>
              <div>
                <p className="text-center text-lg text-teal-900 font-bold">Use mobile scan code to pay </p>
                <QrSvg className="mx-auto mt-4 mb-8 w-8/12 sm:w-6/12" value={user?.upiContent}/>
              </div>
              <div>
                <p className="text-lg font-bold">Please enter the UTR No: </p>
                <TextField
                    className="w-full"
                    id="outlined-basic"
                    label={<span style={{ color: 'black' }}>input 12 -Digit Transaction /UTR id</span>}
                    variant="outlined"
                    type="number"
                    value={rechargeInfo.UTRNumber}
                    onChange={(e) => {
                      if (e.target.value.length === 13) {
                        return
                      }
                      setRechargeInfo({ ...rechargeInfo, UTRNumber: e.target.value })
                    }}
                    sx={{
                      marginTop: 2,
                      color: 'white',
                      '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                          borderColor: '#000'
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#000'
                        }
                      }
                    }}
                />
                {
                  rechargeInfo?.UTRNumber?.length !== 12 ?
                      <p className="mt-1 text-red-500 font-medium text-sm">The UTR Number contains 12 letter your input is invalid</p> : null
                }
                <Uploader setUrl={(imageUrl) => setRechargeInfo({ ...rechargeInfo, imageUrl })} url={rechargeInfo?.imageUrl}/>
                <p className="mt-5 text-lg font-medium text-center">
                  Click Confirm, only after amount
                  deducted from your account. we will
                  manually verify your transaction.
                  Are you sure?
                </p>
                <Button
                    disabled={rechargeInfo.imageUrl && rechargeInfo.UTRNumber?.length === 12 ? false : true}
                    className="w-full"
                    sx={{
                      bgcolor: 'rgb(45 212 191)',
                      marginTop: 5,
                      border: '1px solid rgb(94 234 212)',
                      padding: 1,
                      color: '#000',
                      borderRadius: 2,
                      fontSize: 14,
                      fontWeight: 600,
                      boxShadow: 'none',
                      textTransform: 'capitalize',
                      '&:hover': {
                        bgcolor: 'rgb(20 184 166)',
                        color: '#000'
                      }
                    }}
                    onClick={createRechargeRequest}
                >Confirm</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}
