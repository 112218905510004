import React, { useEffect, useState } from 'react'
import Header from '../Components/header'
import { Bottels } from '../assets'
import { Button } from '@mui/material'
import { useSelector } from 'react-redux'
import { TRANSACTION_TYPES } from '../helper/enum'
import { DateTime } from 'luxon'
import TransactionService from '../service/transaction.service'
import ReactGA from 'react-ga4'

export default function Transactions() {
  ReactGA.send({ hitType: 'pageview', page: '/transactions', title: 'Transaction' })

  const { user } = useSelector(state => state.user)

  const [ transactions, setTransactions ] = useState([])
  const [ tabIndex, setTabIndex ] = useState(0)

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchTransactions(user?._id)
  }, [])

  async function fetchTransactions(userId) {
    try {
      const transactions = await TransactionService.getTransactions(userId)
      setTransactions(transactions?.data)
    } catch (error) {
      console.log(error)
    }
  }

  return (
      <div className="h-screen bg-gradient-to-b from-teal-500 to-white">
        <Header navigation={'/'}/>
        <div className="mt-12">
          <p className="text-2xl font-bold text-center mb-8">Transactions</p>
          <div className="mb-8 flex flex-row items-center justify-around">
            <Button className="w-5/12"
                    sx={{
                      bgcolor: '#15B8A8',
                      color: 'white',
                      padding: 1,
                      fontSize: 14,
                      outline: !tabIndex ? '2px solid #4edece' : '',
                      fontWeight: '700',
                      borderRadius: 5,
                      boxShadow: 'none',
                      '&:hover': {
                        bgcolor: '#1aa598',
                        color: 'white'
                      }
                    }}
                    onClick={() => setTabIndex(0)}

            >
              Transactions
            </Button>
            <Button className="w-5/12 text-lg py-4"
                    sx={{
                      bgcolor: '#15B8A8',
                      color: 'white',
                      padding: 1,
                      fontSize: 14,
                      outline: tabIndex ? '2px solid #4edece' : '',
                      fontWeight: '700',
                      borderRadius: 5,
                      boxShadow: 'none',
                      '&:hover': {
                        bgcolor: '#1aa598',
                        color: 'white'
                      }
                    }}
                    onClick={() => setTabIndex(1)}
            >
              Withdrawals
            </Button>
          </div>
          <div className="overflow-y-scroll mb-12">
            {
              transactions?.map((transaction, index) => {
                return (
                    <div key={index}>
                      {
                        !tabIndex ?
                            <>
                              {
                                [ TRANSACTION_TYPES.APP_EARNING, TRANSACTION_TYPES.RECHARGE, TRANSACTION_TYPES.BUY_PLAN, TRANSACTION_TYPES.BONUS_EARNING ].includes(transaction.transactionType) ?
                                    <div className="mx-auto mb-4 p-4 w-11/12 bg-white rounded-xl border border-teal-300 flex flex-row items-center justify-between">
                                      <div className="flex flex-row items-center gap-6">
                                        <img className="hidden sm:block" src={Bottels} width={70} height={70} alt="bottels img"/>
                                        <div>
                                          <p className="text-xl font-bold">
                                            {
                                              {
                                                [TRANSACTION_TYPES.APP_EARNING]: 'App Earnings',
                                                [TRANSACTION_TYPES.RECHARGE]: 'Recharge message',
                                                [TRANSACTION_TYPES.BUY_PLAN]: 'Buy Plan Expense'
                                              }[transaction?.transactionType] ||
                                              (transaction?.amount === 300 ? 'Invite Bonus' : 'Bonus Earnings')
                                            }
                                          </p>
                                          <p>
                                            {
                                              transaction?.transactionType === TRANSACTION_TYPES.APP_EARNING ?
                                                  `Earned ₹${transaction?.amount} from app earnings! Keep shining!` :
                                                  transaction?.transactionType === TRANSACTION_TYPES.RECHARGE ?
                                                      `Your account has been recharged with ₹${transaction?.amount} on ${DateTime.fromMillis(transaction?.createdOn).
                                                          toFormat('dd MMMM yyyy, hh:mm:ss a')}.` :
                                                      transaction?.transactionType === TRANSACTION_TYPES.BUY_PLAN ?
                                                          `You've purchased a new plan worth ₹${transaction.amount} on ${DateTime.fromMillis(transaction?.createdOn).
                                                              toFormat('dd MMMM yyyy, hh:mm:ss a')}, deducted from your balance.` :
                                                          transaction?.transactionType === TRANSACTION_TYPES.BONUS_EARNING && transaction?.amount === 300 ?
                                                              `Woohoo! You've got an Invite bonus of ₹${transaction?.amount}! Keep up the good work!` :
                                                              `Woohoo! You've got a bonus of ₹${transaction?.amount}! Keep up the good work!`
                                            }
                                          </p>
                                        </div>
                                      </div>
                                      <p
                                          className={`text-sm sm:text-lg text-nowrap ${
                                              [ TRANSACTION_TYPES.BONUS_EARNING, TRANSACTION_TYPES.APP_EARNING, TRANSACTION_TYPES.RECHARGE ].includes(transaction.transactionType) ?
                                                  'text-green-500' :
                                                  'text-red-500'
                                          }`}
                                      >
                                        {[ TRANSACTION_TYPES.BONUS_EARNING, TRANSACTION_TYPES.APP_EARNING, TRANSACTION_TYPES.RECHARGE ].includes(transaction.transactionType) ?
                                            `+ ₹${transaction.amount}` :
                                            `- ₹${transaction.amount}`}
                                      </p>
                                    </div> : null
                              }
                            </> :
                            [ TRANSACTION_TYPES.WITHDRAW, TRANSACTION_TYPES.WITHDRAW_IN_PROCESS ].includes(transaction.transactionType) ?
                                <div className="mx-auto mb-4 p-4 w-11/12 bg-white rounded-xl border border-teal-300 flex flex-row items-center justify-between">
                                  <div className="flex flex-row items-center gap-6">
                                    <img src={Bottels} width={70} height={70} alt="bottels img"/>
                                    <div>
                                      <p className="text-xl font-bold">{transaction.transactionType === 5 ? 'Withdrawal Completed' : 'Withdrawal in process'}</p>
                                      <p>{transaction.transactionType === 5 ?
                                          `You've withdrawn ₹${transaction?.amount} from your balance.` :
                                          `Your withdrawal of ₹${transaction?.amount} is in process.`}</p>
                                    </div>
                                  </div>
                                  <p className="text-sm sm:text-lg text-red-500 text-nowrap">- ₹{transaction.amount}</p>
                                </div> : null
                      }
                    </div>
                )
              })
            }
          </div>
        </div>
      </div>
  )
}
