import React, { useEffect, useState } from 'react'
import { logoImg } from '../assets'
import { PiCrownLight } from 'react-icons/pi'
import { Box, Button, Divider, Modal, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Header from '../Components/header'
import { useSelector } from 'react-redux'
import { TRANSACTION_TYPES } from '../helper/enum'
import { IoMdCloseCircleOutline } from 'react-icons/io'
import TransactionService from '../service/transaction.service'
import { store } from '../store'
import { removeUser } from '../store/reducers/userSlice'
import ReactGA from 'react-ga4'

export default function Profile() {
  ReactGA.send({ hitType: 'pageview', page: '/profile', title: 'Profile' })

  const navigate = useNavigate()
  const { user } = useSelector(state => state.user)

  const [ signOutModal, setSignOutModal ] = useState(false)
  const [ totalIncome, setTotalIncome ] = useState(0)
  const [ totalRecharge, setTotalRecharge ] = useState(0)
  const [ loading, setLoading ] = useState(false)

  useEffect(() => {
    if (user) {
      fetchTransactions(user?._id)
    }
  }, [])

  async function fetchTransactions(userId) {
    try {
      setLoading(true)
      const transactions = await TransactionService.getTransactions(userId)
      await handleTotalCount(transactions?.data)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  async function handleTotalCount(transactionData) {
    let income = 0
    let recharge = 0
    transactionData?.forEach((transaction) => {
      if ([ TRANSACTION_TYPES.APP_EARNING, TRANSACTION_TYPES.BONUS_EARNING ].includes(transaction?.transactionType)) {
        income += transaction?.amount
      } else if ([ TRANSACTION_TYPES.RECHARGE ].includes(transaction?.transactionType)) {
        recharge += transaction?.amount
      }
    })
    setTotalIncome(income)
    setTotalRecharge(recharge)
  }

  return (
      <div className="h-screen bg-gradient-to-b from-teal-500 to-white">
        <Header navigation={'/'}/>

        {/*sign out modal*/}
        <Modal
            open={signOutModal}
            onClose={() => setSignOutModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              outline: 'none',
              '& *': {
                outline: 'none'
              }
            }}
        >
          <Box
              className="rounded-xl w-9/12 lg:w-4/12 xl:w-3/12"
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: '#fff',
                boxShadow: 24
              }}>
            <Typography className="text-center text-teal-900 py-2 bg-teal-300 rounded-t-xl" sx={{ fontWeight: 600 }} id="modal-modal-title" variant="h6" component="h2">
              Purchase
              <IoMdCloseCircleOutline className="absolute top-2 right-2" size={22} onClick={() => setSignOutModal(false)}/>
            </Typography>
            <div className="px-3 py-4">
              <Typography className="text-center" sx={{ fontWeight: 500, fontSize: 18, my: 2 }}>Are you sure you want to buy this plan?</Typography>
            </div>
            <div className="flex flex-row items-center justify-around mb-6">
              <button className="py-1 w-2/6 border border-black bg-white text-teal-900 rounded-full" onClick={() => setSignOutModal(false)}>No</button>
              <button className="py-1 w-2/6 border border-black bg-teal-400 text-white rounded-full" onClick={async () => {
                localStorage.clear()
                await store.dispatch(removeUser())
                navigate('/login')
              }}>Yes
              </button>
            </div>
          </Box>
        </Modal>

        <div className="flex flex-col items-center justify-around mt-16">
          <div className="w-5/6 sm:w-4/6 lg:w-2/6">
            <div className="bg-teal-200 py-8 px-5 sm:px-10 rounded-xl border border-teal-400 shadow-xl">
              <img className="mx-auto rounded-lg" src={logoImg} width={300} height={200} alt="profile"/>
              <div className="w-11/12 sm:w-3/4 lg:w-4/6 mx-auto my-8 py-2 pl-2 flex flex-row items-center justify-start border border-black rounded-lg bg-white">
                <PiCrownLight size={24} className="mr-2"/>
                <Divider orientation="vertical" color={'#000'} flexItem sx={{ marginRight: 1 }}/>
                <p>{user?.mobile}</p>
              </div>
              <div className="mt-8 flex flex-row items-center justify-between">
                <div className="w-1/4">
                  <p className="py-2 font-medium bg-white border border-black rounded-lg text-center text-xs sm:text-base">{user?.balance + user?.bonus || 0}</p>
                  <p className="mt-2 font-medium text-xs sm:text-base text-center">Balance</p>
                </div>
                <div className="w-1/4">
                  <p className="py-2 font-medium bg-white border border-black rounded-lg text-xs sm:text-base text-center">{totalIncome}</p>
                  <p className="mt-2 font-medium text-xs sm:text-base text-center">Income</p>
                </div>
                <div className="w-1/4">
                  <p className="py-2 font-medium bg-white border border-black rounded-lg text-xs sm:text-base text-center">{totalRecharge}</p>
                  <p className="mt-2 font-medium text-xs sm:text-base text-center">Recharge</p>
                </div>
              </div>
            </div>
            <Button
                className="w-full"
                sx={{
                  bgcolor: 'rgb(94 234 212)',
                  marginTop: 8 ,
                  borderRadius: 2,
                  padding: 1,
                  color: '#000',
                  fontSize: 14,
                  fontWeight: 600,
                  border: '1px solid rgb(45 212 191)',
                  boxShadow: 'none',
                  textTransform: 'capitalize',
                  '&:hover': {
                    bgcolor: 'rgb(45 212 191)',
                    color: '#000'
                  }
                }}
                onClick={() => navigate('/track-plan')}
            >Track your plan</Button>
            <Button
                className="w-full"
                sx={{
                  bgcolor: 'rgb(94 234 212)',
                  marginTop: 8 ,
                  borderRadius: 2,
                  padding: 1,
                  color: '#000',
                  fontSize: 14,
                  fontWeight: 600,
                  border: '1px solid rgb(45 212 191)',
                  boxShadow: 'none',
                  textTransform: 'capitalize',
                  '&:hover': {
                    bgcolor: 'rgb(45 212 191)',
                    color: '#000'
                  }
                }}
                onClick={() => !user?.token ? navigate('/login') : setSignOutModal(true)}
            >{!user?.token ? 'Log In' : 'Sign Out'}</Button>
          </div>
        </div>
      </div>
  )
}
